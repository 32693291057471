import React from 'react'

import Layout from 'components/Layout'
import Index from 'components/Index'
import Seo from 'components/Shared/SEO'
import { useText } from 'texts'

const IndexPage = ({ path }) => {
  const { t } = useText()

  return (
    <Layout>
      <Seo
        path={path}
        title={t('home.seo.title')}
        description={t('home.seo.description')}
        keywords={['publicidade digital', 'produtos patrocinados']}
      />
      <Index />
    </Layout>
  )
}

export default IndexPage
