import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

import Colors from 'theme/colors'
import Screens from 'theme/screens'
import { useText } from 'texts'
import { Link } from 'gatsby'

const StyledHero = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Container = styled.div`
  position: relative;
`

const TextContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: start;
  max-width: 40%;
  min-width: 432px;
  padding-right: 20px;
  z-index: 1;

  @media (max-width: ${Screens.md}) {
    min-width: auto;
    max-width: 45%;
  }

  @media (max-width: ${Screens.sm}) {
    position: relative;
    max-width: 100%;
    margin-bottom: 32px;
    padding-right: 0;
    text-align: center;
    align-items: center;
  }
`

const ImageContainer = styled.div`
  display: flex;
  align-self: flex-end;
  width: 50%;
  margin-top: -128px;
  min-height: 434px;

  @media (max-width: ${Screens.sm}) {
    width: 100%;
    min-height: auto;
    overflow: hidden;
    margin-top: 0;
  }
`

const Button = styled(Link)`
  padding: 10px 32px;
  border-radius: 6px;
  background-color: ${Colors.PRIMARY_100};
  color: white;
  text-decoration: none;
  font-weight: 500;

  :hover,
  :focus {
    background-color: ${Colors.PRIMARY_110};
  }
`

const Hero = () => {
  const { t } = useText()

  return (
    <StyledHero>
      <Container className="container full">
        <TextContainer>
          <h1 className="text-primary-100">{t('home.hero.title')}</h1>
          <span className="mb-24">{t('home.hero.description')}</span>
          <Button to="/quero-usar">{t('home.hero.cta')}</Button>
        </TextContainer>
      </Container>
      <ImageContainer>
        <StaticImage
          src="hero.jpg"
          alt={t('home.hero.alt')}
          placeholder="blurred"
          layout="constrained"
        />
      </ImageContainer>
    </StyledHero>
  )
}

export default Hero
