import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

import Colors from 'theme/colors'
import { useText } from 'texts'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

import 'swiper/css';

const PHONE_WIDTH = '242px'
const SCREEN_LEFT = '18px'
const SCREEN_WIDTH = '210px'
const StyledSwiper = styled(Swiper)`
  height: 100%;
`

const Container = styled.div`
  position: relative;
  width: ${PHONE_WIDTH};
  height: 481px;
  background-color: ${Colors.NEUTRAL_500};
  overflow: hidden;
`

const ContentImageContainer = styled.div`
  position: absolute;
  top: 25px;
  left: ${SCREEN_LEFT};
  width: ${SCREEN_WIDTH};
`

const Phone = () => {
  const { t } = useText()

  return (
    <Container>
      <ContentImageContainer>
        <StyledSwiper
          slidesPerView={1}
          spaceBetween={50}
          loop={true}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}

        >
          <SwiperSlide>
            <StaticImage
              src="home_magalu.png"
              alt={t('home.awareness.alt.content')}
              placeholder="blurred"
              layout="constrained"
              quality={100}
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              src="search_magalu.png"
              alt={t('home.awareness.alt.content')}
              placeholder="blurred"
              layout="constrained"
              quality={100}
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              src="home_netshoes.png"
              alt={t('home.awareness.alt.content')}
              placeholder="blurred"
              layout="constrained"
              quality={100}
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              src="search_netshoes.png"
              alt={t('home.awareness.alt.content')}
              placeholder="blurred"
              layout="constrained"
              quality={100}
            />
          </SwiperSlide>
        </StyledSwiper>
      </ContentImageContainer>
    </Container>
  )
}

export default Phone
