import React from 'react'
import styled from 'styled-components'

import Colors from 'theme/colors'
import Screens from 'theme/screens'
import { useText } from 'texts'

import Phone from './Phone'

const StyledAwareness = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${Colors.NEUTRAL_500};
  padding: 8px 0;

  @media (max-width: ${Screens.sm}) {
    padding: 24px 0;
  }
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;

  @media (max-width: ${Screens.sm}) {
    grid-template-columns: none;
    grid-template-rows: auto auto;
    grid-row-gap: 32px;
  }
`

const IllustrationContainer = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: ${Screens.sm}) {
    grid-row: 2;
  }
`

const TextContainer = styled.div`
  align-self: center;
  justify-self: end;
  padding-right: 20px;

  @media (max-width: ${Screens.sm}) {
    text-align: center;
    padding: 0 32px;
  }
`

const Awareness = () => {
  const { t } = useText()

  return (
    <StyledAwareness>
      <Container className="container full">
        <IllustrationContainer>
          <Phone />
        </IllustrationContainer>
        <TextContainer>
          <h2 className="text-neutral-100">{t('home.awareness.title')}</h2>
          <span className="text-neutral-100">
            {t('home.awareness.description')}
          </span>
        </TextContainer>
      </Container>
    </StyledAwareness>
  )
}

export default Awareness
