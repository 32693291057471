import React from 'react'
import styled from 'styled-components'

import Colors from 'theme/colors'
import Screens from 'theme/screens'
import Illustration from 'svgs/usability.svg'
import { useText } from 'texts'

const StyledUsability = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${Colors.GRAY_100};
  padding: 48px 0;

  @media (max-width: ${Screens.sm}) {
    padding: 24px 0;
  }
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;

  @media (max-width: ${Screens.sm}) {
    grid-template-columns: none;
    grid-template-rows: auto auto;
    grid-row-gap: 32px;
  }
`

const TextContainer = styled.div`
  align-self: center;
  padding-right: 48px;

  @media (max-width: ${Screens.sm}) {
    text-align: center;
    padding-right: 0;
  }
`

const IllustrationContainer = styled.div`
  display: flex;
  justify-content: center;
`

const StyledIllustration = styled(Illustration)`
  @media (max-width: ${Screens.md}) {
    max-width: 320px;
    max-height: 210px;
    width: 100%;
    height: 100%;
  }
`

const Usability = () => {
  const { t } = useText()

  return (
    <StyledUsability>
      <Container className="container full">
        <TextContainer>
          <h2 className="text-primary-100">{t('home.usability.title')}</h2>
          <span>{t('home.usability.description')}</span>
        </TextContainer>
        <IllustrationContainer>
          <StyledIllustration />
        </IllustrationContainer>
      </Container>
    </StyledUsability>
  )
}

export default Usability
